var _a;
import { createSlice } from '@reduxjs/toolkit';
import { clone, duplicate, GetSubElement } from 'hub-lib/tools.bin';
import { ConsoleDebug } from '../utils/localstorage.bin';
var initialState = { selectedItems: [], data: [], schedulerTemplate: null, dataStyles: {}, sort: [], gridFilter: { logic: 'and', filters: [] }, gridFilterBase: { logic: 'and', filters: [] } };
function createDataStyles(data, schedulerTemplate) { var dataStyles = {}; if (!(schedulerTemplate !== null && schedulerTemplate !== void 0 && schedulerTemplate.Style))
    return dataStyles; var keys = new Set(Object.values(schedulerTemplate.Style).map(function (v) { return v.key; })); if (schedulerTemplate.Style && (data === null || data === void 0 ? void 0 : data.length) > 0 && keys.size > 0)
    data === null || data === void 0 ? void 0 : data.forEach(function (d) { keys.forEach(function (key) { var value = GetSubElement(d, key); var template = schedulerTemplate.Style[value]; if (template)
        dataStyles[value] = clone(template); }); }); return dataStyles; }
export var authSlice = createSlice({ name: 'grid', initialState: initialState, reducers: { setSort: function (state, action) { state.sort = clone(action.payload); }, setGridFilter: function (state, action) { state.gridFilter = clone(action.payload.adapted); state.gridFilterBase = clone(action.payload.base); }, clearGridFilter: function (state) { state.gridFilter = null; state.gridFilterBase = null; }, selectItems: function (state, action) { state.selectedItems = duplicate(action.payload); }, setData: function (state, action) { var _duplicate; var time8540 = new Date().getTime(); state.data = duplicate(action.payload); var _time8540 = new Date().getTime(); ConsoleDebug("[PERF] REDUX setData duplicate  Elapsed ".concat(_time8540 - time8540, "ms")); var time8533 = new Date().getTime(); state.dataStyles = createDataStyles(action.payload, clone(state.schedulerTemplate)); var _time8533 = new Date().getTime(); ConsoleDebug("[PERF] REDUX createDataStyles Elapsed ".concat(_time8533 - time8533, "ms")); state.selectedItems = (_duplicate = duplicate(state.selectedItems)) === null || _duplicate === void 0 ? void 0 : _duplicate.filter(function (i) { var _state$data; return (_state$data = state.data) === null || _state$data === void 0 ? void 0 : _state$data.some(function (d) { var _i$dataItem; return d['@rid'] == (i === null || i === void 0 ? void 0 : (_i$dataItem = i.dataItem) === null || _i$dataItem === void 0 ? void 0 : _i$dataItem['@rid']); }); }); }, setSchedulerTemplate: function (state, action) { state.schedulerTemplate = duplicate(action.payload); state.dataStyles = createDataStyles(clone(state.data), action.payload); }, initializeSchedulerTemplate: function (state, action) { var newValue = clone(action.payload); state.schedulerTemplate = newValue; state.dataStyles = createDataStyles(clone(state.data), newValue); } } }); // Action creators are generated for each case reducer function
export var selectItems = (_a = authSlice.actions, _a.selectItems), setData = _a.setData, setSchedulerTemplate = _a.setSchedulerTemplate, initializeSchedulerTemplate = _a.initializeSchedulerTemplate, setSort = _a.setSort, setGridFilter = _a.setGridFilter, clearGridFilter = _a.clearGridFilter;
export var gridReducer = authSlice.reducer;
