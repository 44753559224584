var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __await = (this && this.__await) || function (v) { return this instanceof __await ? (this.v = v, this) : new __await(v); }
var __asyncGenerator = (this && this.__asyncGenerator) || function (thisArg, _arguments, generator) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var g = generator.apply(thisArg, _arguments || []), i, q = [];
    return i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i;
    function verb(n) { if (g[n]) i[n] = function (v) { return new Promise(function (a, b) { q.push([n, v, a, b]) > 1 || resume(n, v); }); }; }
    function resume(n, v) { try { step(g[n](v)); } catch (e) { settle(q[0][3], e); } }
    function step(r) { r.value instanceof __await ? Promise.resolve(r.value.v).then(fulfill, reject) : settle(q[0][2], r); }
    function fulfill(value) { resume("next", value); }
    function reject(value) { resume("throw", value); }
    function settle(f, v) { if (f(v), q.shift(), q.length) resume(q[0][0], q[0][1]); }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { eTags } from "./models/KPIsManager.bin";
import { ref_AdvertiserGroups } from "./models/orientdb/ref_AdvertiserGroups.bin";
import { ref_Advertisers } from "./models/orientdb/ref_Advertisers.bin";
import { ref_Brands } from "./models/orientdb/ref_Brands.bin";
import { ref_Products } from "./models/orientdb/ref_Products.bin";
import { ref_Messages } from "./dto/client/ref_Messages.bin";
import { DataProvider } from "./provider";
import { recurseAll } from "tools-lib";
export function SanitizeDuplication(element) {
    delete element['Import'];
    delete element['Deversement'];
    delete element["_id"];
    delete element["@rid"];
    delete element["__v"];
    delete element["Status"];
    /** Case of duplication, remove bound kpis */
    if (element["KPIs"])
        Object.keys(element["KPIs"])
            .filter(function (k) { return k.startsWith("Bound"); })
            .forEach(function (k) { return delete element["KPIs"][k]; });
    return element;
}
export var rmAccents = function (s) {
    return s === null || s === void 0 ? void 0 : s.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/'/g, " ");
};
export function arrayOrNull(arr) {
    return (arr === null || arr === void 0 ? void 0 : arr.length) ? arr : null;
}
export function orderBy(data, descriptors) {
    if (!(descriptors === null || descriptors === void 0 ? void 0 : descriptors.length))
        return data;
    var results = __spreadArray([], data, true);
    descriptors.forEach(function (d) {
        results.sort(function (a, b) {
            var _a, _b;
            var valA = a[d.field];
            var valB = b[d.field];
            if (valA === valB)
                return 0;
            if (typeof valA === "string")
                return valA.localeCompare((_a = valB === null || valB === void 0 ? void 0 : valB.toString) === null || _a === void 0 ? void 0 : _a.call(valB));
            if (typeof valB === "string")
                return valB.localeCompare((_b = valA === null || valA === void 0 ? void 0 : valA.toString) === null || _b === void 0 ? void 0 : _b.call(valA));
            if (typeof valA === "number") {
                if (Number.isNaN(valB))
                    return -1;
                return valA - Number(valB);
            }
            if (typeof valB === "number") {
                if (Number.isNaN(valA))
                    return 1;
                return valB - Number(valA);
            }
            if (typeof valA === "boolean" && (valB === undefined || valB === null))
                return -1;
            if (typeof valB === "boolean" && (valA === undefined || valA === null))
                return 1;
            if (valA === valB)
                return 0;
            if (valA > valB)
                return 1;
            if (valB > valA)
                return -1;
            return 0;
        });
        if (d.dir === "desc")
            results.reverse();
    });
    return results;
}
export var JoinElements = function (obj) {
    var templ = function (val) {
        var _a, _b;
        if ((val === null || val === void 0 ? void 0 : val.cluster) !== undefined && (val === null || val === void 0 ? void 0 : val.position) !== undefined)
            return val.toString();
        if (typeof val === "object")
            return JSON.stringify(val);
        return (_b = (_a = val === null || val === void 0 ? void 0 : val.toString) === null || _a === void 0 ? void 0 : _a.call(val)) !== null && _b !== void 0 ? _b : val;
    };
    return obj.filter(function (e) { return e !== undefined && e !== null; })
        .filter(function (e) { return e != ""; })
        .map(templ)
        .join("|");
};
export function toDictionary(array, key) {
    var dico = {};
    array === null || array === void 0 ? void 0 : array.forEach(function (e) { return dico[key(e)] = e; });
    return dico;
}
export function toDictionaryValue(array, key, value) {
    var dico = {};
    array === null || array === void 0 ? void 0 : array.forEach(function (e) { return dico[key(e)] = value(e); });
    return dico;
}
export function toDictionaryList(array, key) {
    var dico = {};
    array === null || array === void 0 ? void 0 : array.forEach(function (e) {
        if (!dico[key(e)])
            dico[key(e)] = [e];
        else
            dico[key(e)].push(e);
    });
    return dico;
}
export function propertyOf(name) { return name; }
export function propertiesOf() {
    var names = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        names[_i] = arguments[_i];
    }
    return names;
}
export function Instantiate(typeTargt, data) {
    var target = Object.create(typeTargt.prototype);
    return Object.assign(target, data);
}
export function distinct(array, key) {
    if (key === void 0) { key = function (e) { return e === null || e === void 0 ? void 0 : e.toString(); }; }
    var res = [];
    var dico = {};
    array === null || array === void 0 ? void 0 : array.forEach(function (e) {
        if (!dico[key(e)]) {
            dico[key(e)] = e;
            res.push(e);
        }
    });
    return res;
}
export function groupBy(array, key, skipUndefined) {
    if (skipUndefined === void 0) { skipUndefined = false; }
    var res = {};
    for (var _i = 0, array_1 = array; _i < array_1.length; _i++) {
        var e = array_1[_i];
        var keyFind = key(e);
        if (skipUndefined && keyFind === undefined) {
            continue;
        }
        if (!res[keyFind]) {
            res[keyFind] = [];
        }
        res[keyFind].push(e);
    }
    return res;
}
export function groupByAsync(array, key) {
    return __awaiter(this, void 0, Promise, function () {
        var res, _i, array_2, e, keyStr;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    res = {};
                    _i = 0, array_2 = array;
                    _a.label = 1;
                case 1:
                    if (!(_i < array_2.length)) return [3 /*break*/, 4];
                    e = array_2[_i];
                    return [4 /*yield*/, key(e)];
                case 2:
                    keyStr = _a.sent();
                    if (!res[keyStr]) {
                        res[keyStr] = [];
                    }
                    res[keyStr].push(e);
                    _a.label = 3;
                case 3:
                    _i++;
                    return [3 /*break*/, 1];
                case 4:
                    array.forEach(function (e) {
                    });
                    return [2 /*return*/, res];
            }
        });
    });
}
export function truncate(str, num, dot) {
    if (!str)
        return str;
    if (str.length <= num)
        return str;
    return "".concat(str.slice(0, dot ? num - 3 : num)).concat(dot ? "..." : "");
}
/**
 * @description Prépare et ordonne les elements pour la colonne de filtre
 * @author Bastien Douib
 * @export
 * @template T
 * @param {ClassProperty[]} _metas
 * @param {Set<String>} [shouldNotBeFiltered=new Set([])]
 * @returns {*}  {(("Dates" | "Hierarchy" | keyof T)[])}
 */
export function orderMetaDatas(_metas, shouldNotBeFiltered) {
    if (shouldNotBeFiltered === void 0) { shouldNotBeFiltered = new Set([]); }
    var metas = __spreadArray([], _metas, true);
    var orders = [];
    if (metas.filter(function (m) { return m.name === "Start" || m.name === "End"; }).length >= 1) {
        orders.push("Dates");
        metas = metas.filter(function (m) { return !["Start", "End"].includes(m.name); });
    }
    if (metas.filter(function (m) {
        return [
            ref_AdvertiserGroups.name,
            ref_Advertisers.name,
            ref_Brands.name,
            ref_Products.name,
        ].includes(m.linkedClass);
    }).length) {
        orders.push("Hierarchy");
        metas = metas.filter(function (m) {
            return ![
                ref_AdvertiserGroups.name,
                ref_Advertisers.name,
                ref_Brands.name,
                ref_Products.name,
            ].includes(m.linkedClass);
        });
    }
    orders = __spreadArray(__spreadArray([], orders, true), metas.filter(function (m) { return !shouldNotBeFiltered.has(m.linkedClass); }).map(function (e) { return e.name; }), true);
    return orders;
}
export function distinctValues(array) {
    var uniqueArray = array.filter(function (item, pos) { return array.indexOf(item) == pos; });
    return uniqueArray.filter(function (e) { return e != undefined; });
}
export function firstOrArray(array, origin) {
    if (Array.isArray(origin))
        return array;
    return array[0];
}
export function firstOrDefault(array) {
    if (array.length)
        return array[0];
    return undefined;
}
export function firstOrDefaultWhere(array, test) {
    if (array === null || array === void 0 ? void 0 : array.length) {
        for (var _i = 0, array_3 = array; _i < array_3.length; _i++) {
            var element = array_3[_i];
            if (test(element))
                return element;
        }
    }
    return undefined;
}
export function agregateEntries(original, filter) {
    if (!Array.isArray(original))
        original = [original];
    return original.filter(function (o) { return filter.includes(o); });
}
export function extract(obj, data) {
    if (!data)
        return null;
    var res = {};
    Object.entries(obj).forEach(function (e) {
        if (data.hasOwnProperty(e[1]))
            res[e[0]] = data[e[1]];
    });
    if (!Object.keys(res).length)
        return null;
    return res;
}
export function getDifferenceKeys(data1, data2) {
    var _a, _b;
    var keys = [];
    var allKeys = Array.from(new Set(__spreadArray(__spreadArray([], Object.keys(data1 !== null && data1 !== void 0 ? data1 : {}), true), Object.keys(data2 !== null && data2 !== void 0 ? data2 : {}), true)));
    for (var _i = 0, allKeys_1 = allKeys; _i < allKeys_1.length; _i++) {
        var key = allKeys_1[_i];
        if (JSON.stringify((_a = data1 === null || data1 === void 0 ? void 0 : data1[key]) !== null && _a !== void 0 ? _a : {}) !== JSON.stringify((_b = data2 === null || data2 === void 0 ? void 0 : data2[key]) !== null && _b !== void 0 ? _b : {}))
            keys.push(key);
    }
    return keys;
}
export function toArray(obj) {
    return Array.isArray(obj) ? obj : [obj];
}
export function safeArray(array) {
    return array !== null && array !== void 0 ? array : [];
}
export function safeNumber(value) {
    if (isNaN(value))
        return 0;
    if (typeof value === "string")
        return Number(value);
    return value !== null && value !== void 0 ? value : 0;
}
/**
 * @description Determine if an array contains one single value and return it
 * @author Bastien Douib
 * @param {string[]} array
 * @returns {*}  {string}
 */
export function arrayUnique(array) {
    var newArray = __spreadArray([], new Set(array.filter(Boolean)), true);
    return newArray.length === 1 ? newArray[0] : undefined;
}
export function extractSub(data, props, defaultValue) {
    if (!data)
        return null;
    var res = {};
    props.forEach(function (e) {
        if (data.hasOwnProperty(e))
            res[e] = data[e];
        else if (defaultValue !== undefined)
            res[e] = defaultValue;
    });
    if (!Object.keys(res).length)
        return null;
    return res;
}
export function removeSub(data, props) {
    if (!data)
        return null;
    var res = clone(data);
    props.forEach(function (e) {
        if (data.hasOwnProperty(e))
            delete res[e];
    });
    if (!Object.keys(res).length)
        return null;
    return res;
}
/**
 * @description return true if params are identical
 */
export function compareObjects(_o, _p) {
    var o = duplicate(_o);
    var p = duplicate(_p);
    var i, keysO = Object.keys(o).sort(), keysP = Object.keys(p).sort();
    if (keysO.length !== keysP.length)
        return false; //not the same nr of keys
    if (keysO.join("") !== keysP.join(""))
        return false; //different keys
    for (i = 0; i < keysO.length; ++i) {
        if (o[keysO[i]] instanceof Array) {
            if (!(p[keysO[i]] instanceof Array))
                return false;
            if (o[keysO[i]].length != p[keysO[i]].length)
                return false;
            for (var j = 0; j < o[keysO[i]].length; j++) {
                var ret = compareObjects(o[keysO[i]][j], p[keysO[i]][j]);
                if (!ret)
                    return false;
            }
            //if (p[keysO[i]].sort().join("") !== o[keysO[i]].sort().join("")) return false;
        }
        else if (o[keysO[i]] instanceof Date) {
            if (!(p[keysO[i]] instanceof Date))
                return false;
            if ("" + o[keysO[i]] !== "" + p[keysO[i]])
                return false;
        }
        else if (o[keysO[i]] instanceof Function) {
            if (!(p[keysO[i]] instanceof Function))
                return false;
            //ignore functions, or check them regardless?
        }
        else if (o[keysO[i]] instanceof Object) {
            if (!(p[keysO[i]] instanceof Object))
                return false;
            if (o[keysO[i]] === o) {
                //self reference?
                if (p[keysO[i]] !== p)
                    return false;
            }
            else if (compareObjects(o[keysO[i]], p[keysO[i]]) === false)
                return false; //WARNING: does not deal with circular refs other than ^^
        }
        else if (o[keysO[i]] !== p[keysO[i]])
            //change !== to != for loose comparison
            return false; //not the same value
    }
    return true;
}
export function orderKeys(_o) {
    var o = duplicate(_o);
    var keysO = Object.keys(o).sort();
    for (var i = 0; i < keysO.length; ++i) {
        if (o[keysO[i]] instanceof Object)
            o[keysO[i]] = orderKeys(o[keysO[i]]);
    }
    return o;
}
export function serializeObject(_o) {
    return JSON.stringify(_o);
}
export function ClearEmptyValues(data) {
    if (!data)
        return null;
    var newParams = duplicate(data);
    for (var _i = 0, _a = Object.entries(data); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], value = _b[1];
        if (Array.isArray(value) && value.length === 0) {
            delete newParams[key];
        }
    }
    return newParams;
}
export function ClearUndefinedValues(data) {
    if (!data)
        return null;
    var newParams = duplicate(data);
    for (var _i = 0, _a = Object.entries(data); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], value = _b[1];
        if (value === undefined) {
            delete newParams[key];
        }
    }
    return newParams;
}
export function compareArrays(a1, a2) {
    if ((a1 && !a2) || (!a1 && a2))
        return false;
    if (!a1)
        a1 = [];
    if (!a2)
        a2 = [];
    var a2Sorted = __spreadArray([], a2, true).sort();
    return a1.length === a2.length && __spreadArray([], a1, true).sort().every(function (value, index) { return value === a2Sorted[index]; });
}
export function includeArrays(a1, a2) {
    if ((a1 && !a2) || (!a1 && a2))
        return false;
    if (!a1)
        a1 = [];
    if (!a2)
        a2 = [];
    return a1.length >= a2.length && a2.every(function (e) { return a1.includes(e); });
}
export function duplicate(data) {
    return JSON.parse(JSON.stringify(data !== null && data !== void 0 ? data : {}));
}
export function clone(data, visited) {
    if (visited === void 0) { visited = new Map(); }
    if (data === undefined || data === null)
        return data;
    if (typeof data === 'object') {
        if (visited.has(data))
            return visited.get(data);
        if (Array.isArray(data))
            return __spreadArray([], data.map(function (d) { return clone(d, visited); }), true);
        if (data instanceof Date)
            return new Date(data);
        var copy_1 = Object.assign(Object.create(Object.getPrototypeOf(data)), data);
        visited.set(data, copy_1);
        Object.entries(data).forEach(function (_a) {
            var k = _a[0], v = _a[1];
            copy_1[k] = clone(v, visited);
        });
        return copy_1;
    }
    return data;
}
export function memoize(func, options) {
    var results = {};
    var timeouts = {};
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var argsKey = (options === null || options === void 0 ? void 0 : options.key) ? options === null || options === void 0 ? void 0 : options.key.apply(options, args) : JSON.stringify(args);
        if (!results[argsKey]) {
            results[argsKey] = func.apply(void 0, args);
        }
        var keyExpiration = options === null || options === void 0 ? void 0 : options.keyExpiration;
        if (keyExpiration) {
            if (timeouts[argsKey])
                clearTimeout(timeouts[argsKey]);
            timeouts[argsKey] = setTimeout(function () {
                delete results[argsKey];
                console.log("Memoize key ".concat(argsKey, " expired"));
            }, keyExpiration);
        }
        return results[argsKey];
    };
}
;
export function memoizeAsyncBase(func, options) {
    var _this = this;
    var results = {};
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return __awaiter(_this, void 0, void 0, function () {
            var argsKey;
            return __generator(this, function (_a) {
                argsKey = JSON.stringify(args !== null && args !== void 0 ? args : {});
                if (!results[argsKey])
                    results[argsKey] = func.apply(void 0, args);
                return [2 /*return*/, results[argsKey]];
            });
        });
    };
}
;
var memoFunc = memoizeAsyncBase;
export function SetMemoizeAsync(func) {
    memoFunc = func;
}
export function memoizeAsync(func, options) {
    // must be overriden
    return memoFunc(func, options);
}
;
export function duplicateObject(data) {
    var copy = {};
    if (data) {
        Object.entries(data).forEach(function (_a) {
            var k = _a[0], v = _a[1];
            if (typeof v !== "function")
                copy[k] = v;
        });
    }
    return JSON.parse(JSON.stringify(copy));
}
export function roundToTwoDecimals(num) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
}
export function convertToHtmlString(input) {
    return input;
    // const escapedString = input
    //     .replace(/'/g, "'")
    //     .replace(/é/g, "&eacute;")
    //     .replace(/"/g, "&quot;")
    //     .replace(/&/g, "&amp;")
    //     .replace(/</g, "&lt;")
    //     .replace(/>/g, "&gt;");
    // return escapedString;
}
export function roundToNDecimals(num, n) {
    return Math.round((num + Number.EPSILON) * Math.pow(10, n)) / Math.pow(10, n);
}
export var ePropertyOptionFilter;
(function (ePropertyOptionFilter) {
    ePropertyOptionFilter["DiscountNotEmpty"] = "DiscountNotEmpty";
    ePropertyOptionFilter["DistinctIsUnique"] = "DistinctIsUnique";
})(ePropertyOptionFilter || (ePropertyOptionFilter = {}));
export function IndicateurInfoFilter(obj, match) {
    var _a, _b, _c, _d, _e, _f, _g;
    var filter = match['filter'];
    switch (filter) {
        case ePropertyOptionFilter.DiscountNotEmpty:
            var v = obj;
            return ((_a = v === null || v === void 0 ? void 0 : v.CO) === null || _a === void 0 ? void 0 : _a.Value) > 0 || ((_b = v === null || v === void 0 ? void 0 : v.FO) === null || _b === void 0 ? void 0 : _b.Value) > 0 || ((_c = v === null || v === void 0 ? void 0 : v.FOS) === null || _c === void 0 ? void 0 : _c.Value) > 0 || ((_d = v === null || v === void 0 ? void 0 : v.CO) === null || _d === void 0 ? void 0 : _d.Rate) > 0 || ((_e = v === null || v === void 0 ? void 0 : v.FO) === null || _e === void 0 ? void 0 : _e.Rate) > 0 || ((_f = v === null || v === void 0 ? void 0 : v.FOS) === null || _f === void 0 ? void 0 : _f.Rate) > 0;
        case ePropertyOptionFilter.DistinctIsUnique:
            if (Array.isArray(obj))
                return ((_g = distinct(toArray(obj), function (i) { return i[match.subProperty]; })) === null || _g === void 0 ? void 0 : _g.length) == 1;
            return true;
        default:
            return true;
    }
}
export function performances(text, callBack) {
    var start = new Date();
    var res = callBack();
    var end = new Date();
    console.log("".concat(text, ": "), end.getTime() - start.getTime(), " ms");
    return res;
}
export function performancesAsync(text, callBack) {
    return __awaiter(this, void 0, void 0, function () {
        var start, res, end;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    start = new Date();
                    return [4 /*yield*/, callBack()];
                case 1:
                    res = _a.sent();
                    end = new Date();
                    console.log("[PERF] ".concat(text, ": "), end.getTime() - start.getTime(), " ms");
                    return [2 /*return*/, res];
            }
        });
    });
}
export function splitBlocks(elements, blockSize, callBack) {
    if (blockSize === void 0) { blockSize = 100; }
    return __asyncGenerator(this, arguments, function splitBlocks_1() {
        var i;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!callBack)
                        callBack = ReleaseEventLoop;
                    i = 0;
                    _a.label = 1;
                case 1:
                    if (!(i < elements.length)) return [3 /*break*/, 6];
                    return [4 /*yield*/, __await(elements[i])];
                case 2: 
                // Yield (retourner) chaque élément au consommateur de l'itérateur
                return [4 /*yield*/, _a.sent()];
                case 3:
                    // Yield (retourner) chaque élément au consommateur de l'itérateur
                    _a.sent();
                    if (!((i + 1) % blockSize === 0)) return [3 /*break*/, 5];
                    return [4 /*yield*/, __await(callBack())];
                case 4:
                    _a.sent();
                    _a.label = 5;
                case 5:
                    i++;
                    return [3 /*break*/, 1];
                case 6:
                    if (!(elements.length % blockSize !== 0)) return [3 /*break*/, 8];
                    return [4 /*yield*/, __await(callBack())];
                case 7:
                    _a.sent();
                    _a.label = 8;
                case 8: return [2 /*return*/];
            }
        });
    });
}
export function splitBlocksFor(elements, callBack, blockSize) {
    if (blockSize === void 0) { blockSize = 100; }
    return __awaiter(this, void 0, void 0, function () {
        var i;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    i = 0;
                    _a.label = 1;
                case 1:
                    if (!(i < elements.length)) return [3 /*break*/, 4];
                    callBack(elements[i]);
                    if (!((i + 1) % blockSize === 0)) return [3 /*break*/, 3];
                    return [4 /*yield*/, ReleaseEventLoop()];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3:
                    i++;
                    return [3 /*break*/, 1];
                case 4: return [2 /*return*/];
            }
        });
    });
}
export function splitBlocksForAsync(elements, callBack, blockSize) {
    if (blockSize === void 0) { blockSize = 100; }
    return __awaiter(this, void 0, void 0, function () {
        var i;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    i = 0;
                    _a.label = 1;
                case 1:
                    if (!(i < elements.length)) return [3 /*break*/, 5];
                    return [4 /*yield*/, callBack(elements[i])];
                case 2:
                    _a.sent();
                    if (!((i + 1) % blockSize === 0)) return [3 /*break*/, 4];
                    return [4 /*yield*/, ReleaseEventLoop()];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    i++;
                    return [3 /*break*/, 1];
                case 5: return [2 /*return*/];
            }
        });
    });
}
export function GetSubElement(data, prop, _match) {
    if (!data || !prop)
        return null;
    var obj = data;
    if (hasOwnProperty(obj, prop))
        return obj[prop];
    var match = _match ? toArray(_match) : undefined;
    var props = prop.split(".");
    var _loop_1 = function (key) {
        if (Array.isArray(obj))
            obj = obj
                .map(function (e) { return e === null || e === void 0 ? void 0 : e[key]; })
                .filter(Boolean);
        else {
            obj = obj === null || obj === void 0 ? void 0 : obj[key];
            if (Array.isArray(obj))
                obj = obj
                    .filter(Boolean)
                    .filter(function (e) {
                    if (!match)
                        return true;
                    return match.every(function (m) {
                        if (m.subProperty != key)
                            return true;
                        else if ("value" in m)
                            return compareObjects(m['value'], extractSub(e, Object.keys(m['value'])));
                        else if ("filter" in m) {
                            return IndicateurInfoFilter(e, m);
                        }
                    });
                })
                    .filter(Boolean);
        }
    };
    for (var _i = 0, props_1 = props; _i < props_1.length; _i++) {
        var key = props_1[_i];
        _loop_1(key);
    }
    return obj;
}
export function IsIDDB(id) {
    return IsOrientDB(id) || IsMongoDB(id);
}
export function IsOrientDB(id) {
    var _a;
    if (!id || id.length == 0 || !((_a = id.startsWith) === null || _a === void 0 ? void 0 : _a.call(id, '#')))
        return false;
    var part = id.split(":");
    if (part.length != 2)
        return false;
    return part.every(function (p) { return /-?\d+/.test(p); });
}
export function IsMongoDB(id) {
    return id && id.length === 24 && /^[0-9a-fA-F]+$/.test(id);
}
export function SetSubElement(data, prop, value) {
    if (!data)
        return;
    var obj = data;
    var props = prop.split(".");
    for (var i = 0; i < props.length - 1; i++) {
        var key = props[i];
        if (!obj.hasOwnProperty(key))
            obj[key] = {};
        obj = obj[key];
    }
    obj[props[props.length - 1]] = value;
}
export function RemoveKeyChar(data, toReplace, newStr) {
    if (Array.isArray(data)) {
        data.forEach(function (d) { return RemoveKeyChar(d, toReplace, newStr); });
        return data;
    }
    if (data && typeof data === "object") {
        Object.entries(data).forEach(function (_a) {
            var k = _a[0], v = _a[1];
            if (typeof k === "string") {
                var newKey = k.replace(toReplace, newStr);
                if (newKey !== k) {
                    delete data[k];
                    data[newKey] = RemoveKeyChar(v, toReplace, newStr);
                }
                else {
                    data[k] = RemoveKeyChar(v, toReplace, newStr);
                }
            }
            else {
                data[k] = RemoveKeyChar(v, toReplace, newStr);
            }
        });
    }
    return data;
}
export function GetFlatElements(elements, propChildren) {
    var _a;
    var _b;
    var subElements = (_b = elements === null || elements === void 0 ? void 0 : elements.map(function (e) { return GetFlatElements(e[propChildren], propChildren); })) !== null && _b !== void 0 ? _b : [];
    return (_a = (elements !== null && elements !== void 0 ? elements : [])).concat.apply(_a, subElements);
}
export function JSONEqualityComparer(a, b) {
    return JSON.stringify(a !== null && a !== void 0 ? a : {}) == JSON.stringify(b !== null && b !== void 0 ? b : {});
}
export function Serialize(data) {
    var ToString = "";
    try {
        ToString = data === null || data === void 0 ? void 0 : data.toString();
    }
    catch (error) {
    }
    var SerializedData = {};
    try {
        SerializedData = data && JSON.parse(JSON.stringify(data));
    }
    catch (error) {
    }
    return {
        SerializedData: SerializedData,
        ToString: ToString,
    };
}
export function SerializeError(error) {
    var _a, _b;
    var ToString = "";
    try {
        ToString = error === null || error === void 0 ? void 0 : error.toString();
    }
    catch (error) {
    }
    var SerializedError = {};
    try {
        SerializedError = error && JSON.parse(JSON.stringify(error));
    }
    catch (error) {
    }
    return {
        SerializedError: SerializedError,
        ToString: ToString,
        Message: error === null || error === void 0 ? void 0 : error.message,
        ResponseData: (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data,
        Stack: error === null || error === void 0 ? void 0 : error.stack,
        Code: error === null || error === void 0 ? void 0 : error.code,
        CodeResponse: (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.code,
    };
}
var _releaseEventLoopOverride = null;
export var SetReleaseEventLoopOverride = function (_override) {
    _releaseEventLoopOverride = _override;
};
export function ReleaseEventLoop() {
    if (_releaseEventLoopOverride)
        return _releaseEventLoopOverride();
    return new Promise(function (res, rej) {
        setImmediate ? setImmediate(function () { return res(undefined); }) : setTimeout(function () { return res(undefined); }, 0);
    });
}
export function hasConfig(config, keysToIgnore) {
    if (keysToIgnore === void 0) { keysToIgnore = ["Start", "End", "ViewMode", "CrossedtableConfig"]; }
    var newObject = __assign({}, config);
    for (var _i = 0, keysToIgnore_1 = keysToIgnore; _i < keysToIgnore_1.length; _i++) {
        var key = keysToIgnore_1[_i];
        delete newObject[key];
    }
    return Object.values(newObject).filter(Boolean).length > 0;
}
export function GetHashCode(obj) {
    if (!obj)
        return "";
    var str = JSON.stringify(obj);
    var hash = 0, i, chr;
    if (str.length === 0)
        return hash;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}
export function GenerateKey() {
    var els = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        els[_i] = arguments[_i];
    }
    return els.map(function (el) {
        var toString = "";
        if (Array.isArray(el))
            toString = el.join("-");
        else if (typeof el === 'object')
            toString = GetHashCode(el).toString();
        else
            toString = el;
        return "[".concat(toString, "]");
    }).join("-");
}
export function IsPromise(p) {
    if (p !== null &&
        typeof p === 'object' &&
        typeof p.then === 'function' &&
        typeof p.catch === 'function') {
        return true;
    }
    return false;
}
export function clearEmptyValues(object) {
    var newObject = __assign({}, object);
    if (object)
        for (var _i = 0, _a = Object.entries(object); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            if ((Array.isArray(value) && value.length === 0) || value == undefined) {
                delete newObject[key];
            }
        }
    return newObject;
}
// format response with modifiers
export function formater(children, modifiers) {
    if (children.length === 0 || typeof children[0] !== "object")
        return children;
    return children.map(function (child) {
        var formatedChild = __assign({}, child);
        for (var _i = 0, _a = Object.keys(modifiers); _i < _a.length; _i++) {
            var key = _a[_i];
            if (modifiers[key]) {
                formatedChild[modifiers[key]] = Array.isArray(formatedChild[key])
                    ? formater(formatedChild[key], modifiers)
                    : formatedChild[key];
                delete formatedChild[key];
            }
        }
        return formatedChild;
    });
}
export function splitArray(_datas, splitNumber) {
    var splited = [];
    var datas = __spreadArray([], _datas, true);
    while (datas.length > 0) {
        splited.push(datas.splice(0, splitNumber));
    }
    return splited;
}
export var Typed = function (d) { return d; };
export var hasOwnProperty = function (object, property) {
    if (!object)
        return false;
    return Object.prototype.hasOwnProperty.call(object, property);
};
export var sleep = function (time) {
    return new Promise(function (res, rej) {
        setTimeout(function () {
            res(undefined);
        }, time);
    });
};
export var groupByTag = function (properties) {
    var tags = Object.values(eTags);
    var group = {};
    tags.forEach(function (e) { return group[e] = []; });
    for (var _i = 0, _a = properties.filter(function (e) { return e.Tags; }); _i < _a.length; _i++) {
        var p = _a[_i];
        for (var _b = 0, tags_1 = tags; _b < tags_1.length; _b++) {
            var tag = tags_1[_b];
            if (p.Tags.includes(tag)) {
                group[tag].push(p.Name);
            }
        }
    }
    return group;
};
export function RGBAToHexA(rgba, forceRemoveAlpha) {
    if (forceRemoveAlpha === void 0) { forceRemoveAlpha = false; }
    return "#" + rgba.replace(/^rgba?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
        .split(',') // splits them at ","
        .filter(function (string, index) { return !forceRemoveAlpha || index !== 3; })
        .map(function (string) { return parseFloat(string); }) // Converts them to numbers
        .map(function (number, index) { return index === 3 ? Math.round(number * 255) : number; }) // Converts alpha to 255 number
        .map(function (number) { return number.toString(16); }) // Converts numbers to hex
        .map(function (string) { return string.length === 1 ? "0" + string : string; }) // Adds 0 when length of one number is 1
        .join(""); // Puts the array to togehter to a string
}
export function ToArrayObject(obj) {
    var params = {};
    if (obj) {
        if (obj.AdvertiserGroup)
            params.AdvertiserGroup = toArray(obj.AdvertiserGroup);
        if (obj.Advertiser)
            params.Advertiser = toArray(obj.Advertiser);
        if (obj.Brand)
            params.Brand = toArray(obj.Brand);
        if (obj.Product)
            params.Product = toArray(obj.Product);
        if (obj.Campaign)
            params.Campaign = toArray(obj.Campaign);
    }
    return params;
}
export function SplitArrayInChunks(myArray, sizeOfChunk) {
    var result = [];
    for (var i = 0; i < myArray.length; i += sizeOfChunk) {
        var sousTableau = myArray.slice(i, i + sizeOfChunk);
        result.push(sousTableau);
    }
    return result;
}
export function removeDiacritics(str) {
    if (typeof str !== 'string')
        return str;
    var defaultDiacriticsRemovalMap = [
        { 'base': 'A', 'letters': /[\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F]/g },
        { 'base': 'AA', 'letters': /[\uA732]/g },
        { 'base': 'AE', 'letters': /[\u00C6\u01FC\u01E2]/g },
        { 'base': 'AO', 'letters': /[\uA734]/g },
        { 'base': 'AU', 'letters': /[\uA736]/g },
        { 'base': 'AV', 'letters': /[\uA738\uA73A]/g },
        { 'base': 'AY', 'letters': /[\uA73C]/g },
        { 'base': 'B', 'letters': /[\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181]/g },
        { 'base': 'C', 'letters': /[\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E]/g },
        { 'base': 'D', 'letters': /[\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779]/g },
        { 'base': 'DZ', 'letters': /[\u01F1\u01C4]/g },
        { 'base': 'Dz', 'letters': /[\u01F2\u01C5]/g },
        { 'base': 'E', 'letters': /[\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E]/g },
        { 'base': 'F', 'letters': /[\u0046\u24BB\uFF26\u1E1E\u0191\uA77B]/g },
        { 'base': 'G', 'letters': /[\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E]/g },
        { 'base': 'H', 'letters': /[\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D]/g },
        { 'base': 'I', 'letters': /[\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197]/g },
        { 'base': 'J', 'letters': /[\u004A\u24BF\uFF2A\u0134\u0248]/g },
        { 'base': 'K', 'letters': /[\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2]/g },
        { 'base': 'L', 'letters': /[\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780]/g },
        { 'base': 'LJ', 'letters': /[\u01C7]/g },
        { 'base': 'Lj', 'letters': /[\u01C8]/g },
        { 'base': 'M', 'letters': /[\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C]/g },
        { 'base': 'N', 'letters': /[\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4]/g },
        { 'base': 'NJ', 'letters': /[\u01CA]/g },
        { 'base': 'Nj', 'letters': /[\u01CB]/g },
        { 'base': 'O', 'letters': /[\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C]/g },
        { 'base': 'OI', 'letters': /[\u01A2]/g },
        { 'base': 'OO', 'letters': /[\uA74E]/g },
        { 'base': 'OU', 'letters': /[\u0222]/g },
        { 'base': 'P', 'letters': /[\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754]/g },
        { 'base': 'Q', 'letters': /[\u0051\u24C6\uFF31\uA756\uA758\u024A]/g },
        { 'base': 'R', 'letters': /[\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782]/g },
        { 'base': 'S', 'letters': /[\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784]/g },
        { 'base': 'T', 'letters': /[\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786]/g },
        { 'base': 'TZ', 'letters': /[\uA728]/g },
        { 'base': 'U', 'letters': /[\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244]/g },
        { 'base': 'V', 'letters': /[\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245]/g },
        { 'base': 'VY', 'letters': /[\uA760]/g },
        { 'base': 'W', 'letters': /[\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72]/g },
        { 'base': 'X', 'letters': /[\u0058\u24CD\uFF38\u1E8A\u1E8C]/g },
        { 'base': 'Y', 'letters': /[\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE]/g },
        { 'base': 'Z', 'letters': /[\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762]/g },
        { 'base': 'a', 'letters': /[\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g },
        { 'base': 'aa', 'letters': /[\uA733]/g },
        { 'base': 'ae', 'letters': /[\u00E6\u01FD\u01E3]/g },
        { 'base': 'ao', 'letters': /[\uA735]/g },
        { 'base': 'au', 'letters': /[\uA737]/g },
        { 'base': 'av', 'letters': /[\uA739\uA73B]/g },
        { 'base': 'ay', 'letters': /[\uA73D]/g },
        { 'base': 'b', 'letters': /[\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253]/g },
        { 'base': 'c', 'letters': /[\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184]/g },
        { 'base': 'd', 'letters': /[\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A]/g },
        { 'base': 'dz', 'letters': /[\u01F3\u01C6]/g },
        { 'base': 'e', 'letters': /[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g },
        { 'base': 'f', 'letters': /[\u0066\u24D5\uFF46\u1E1F\u0192\uA77C]/g },
        { 'base': 'g', 'letters': /[\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F]/g },
        { 'base': 'h', 'letters': /[\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265]/g },
        { 'base': 'hv', 'letters': /[\u0195]/g },
        { 'base': 'i', 'letters': /[\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g },
        { 'base': 'j', 'letters': /[\u006A\u24D9\uFF4A\u0135\u01F0\u0249]/g },
        { 'base': 'k', 'letters': /[\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3]/g },
        { 'base': 'l', 'letters': /[\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747]/g },
        { 'base': 'lj', 'letters': /[\u01C9]/g },
        { 'base': 'm', 'letters': /[\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F]/g },
        { 'base': 'n', 'letters': /[\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5]/g },
        { 'base': 'nj', 'letters': /[\u01CC]/g },
        { 'base': 'o', 'letters': /[\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g },
        { 'base': 'oi', 'letters': /[\u01A3]/g },
        { 'base': 'ou', 'letters': /[\u0223]/g },
        { 'base': 'oo', 'letters': /[\uA74F]/g },
        { 'base': 'p', 'letters': /[\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755]/g },
        { 'base': 'q', 'letters': /[\u0071\u24E0\uFF51\u024B\uA757\uA759]/g },
        { 'base': 'r', 'letters': /[\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783]/g },
        { 'base': 's', 'letters': /[\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B]/g },
        { 'base': 't', 'letters': /[\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787]/g },
        { 'base': 'tz', 'letters': /[\uA729]/g },
        { 'base': 'u', 'letters': /[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g },
        { 'base': 'v', 'letters': /[\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C]/g },
        { 'base': 'vy', 'letters': /[\uA761]/g },
        { 'base': 'w', 'letters': /[\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73]/g },
        { 'base': 'x', 'letters': /[\u0078\u24E7\uFF58\u1E8B\u1E8D]/g },
        { 'base': 'y', 'letters': /[\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF]/g },
        { 'base': 'z', 'letters': /[\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763]/g }
    ];
    for (var _i = 0, defaultDiacriticsRemovalMap_1 = defaultDiacriticsRemovalMap; _i < defaultDiacriticsRemovalMap_1.length; _i++) {
        var diacritic = defaultDiacriticsRemovalMap_1[_i];
        str = str.replace(diacritic.letters, diacritic.base);
    }
    return str;
}
export function SameDayDates(date1, date2) {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}
// function that capitalize first letter of sentence and the rest of the sentence is lowercase
export function CapitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
export function GetDuplicatedMessages(_a) {
    var _b;
    var Support = _a.Support, AdvertiserGroup = _a.AdvertiserGroup, BroadcastArea = _a.BroadcastArea, Currency = _a.Currency, Start = _a.Start;
    return __awaiter(this, void 0, void 0, function () {
        var duplicates;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!Support || !AdvertiserGroup || !BroadcastArea || !Currency)
                        return [2 /*return*/, []];
                    return [4 /*yield*/, DataProvider.search(ref_Messages.name, __assign({ Active: true, Source: "ADWONE", Support: Support, AdvertiserGroup: AdvertiserGroup, BroadcastArea: BroadcastArea, Currency: Currency, properties: ["Start", "Advertiser", "Brand", "Product", "AdvertiserGroup", "BroadcastArea", "Currency", "Support", "Campaign", "Active",
                                "AdvertiserGroup.Name as AdvertiserGroupName", "Advertiser.Name as AdvertiserName", "Brand.Name as BrandName", "Product.Name as ProductName", "Campaign.Name as CampaignName"] }, (Start ? { _takeAll: true, _strictParams: { Start: (_b = new Date(Start)) === null || _b === void 0 ? void 0 : _b.toString() } } : {})))];
                case 1:
                    duplicates = _c.sent();
                    return [2 /*return*/, duplicates];
            }
        });
    });
}
export function removePasswordProperties(data) {
    var cloneElement = clone(data);
    recurseAll(cloneElement, function (rec) {
        if (rec && typeof rec === 'object') {
            var keys = Object.keys(rec);
            // get keys that contain password
            var passwordKeys = keys.filter(function (k) { return k.toLowerCase().includes("password"); });
            // remove password keys
            passwordKeys.forEach(function (k) { return rec[k] = "********"; });
        }
    });
    return cloneElement;
}
export function isPasswordStrong(password) {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])(?=.{10,})/.test(password);
}
