var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ref_PropertyType } from "hub-lib/models/orientdb/ref_PropertyType.bin";
import { eIndicateurType, isDirectionPercent } from "./index.bin";
import { distinct, IsIDDB, JoinElements, memoize, memoizeAsync, memoizeAsyncBase, propertyOf, ReleaseEventLoop, splitBlocks, splitBlocksFor, toArray, toDictionary } from "hub-lib/tools.bin";
import { Format, GetKPITemplate, GetPropTemplate, GetPropertyTypeTemplate, HTMLToString, Sort, propsNeededForFormat, GetCellTemplate } from "format-lib/index.bin";
import { eKPIType, IsPrice } from "hub-lib/models/KPIsManager.bin";
import { DataProvider } from "hub-lib/provider";
import { FormatPrice, Trad } from "trad-lib";
import { getUniqueWeekYears } from "tools-lib";
import { ref_Messages } from "hub-lib/dto/client/ref_Messages.bin";
import { ref_Periodicity } from "hub-lib/models/orientdb/ref_Periodicity.bin";
import { ref_Property } from "hub-lib/models/orientdb/ref_Property.bin";
import { ref_Currencies } from "hub-lib/models/orientdb/ref_Currencies.bin";
import { AggregatorFactory } from "hub-lib/aggregator/AggregatorFactory";
import { ref_Campaigns } from "hub-lib/dto/client/ref_Campaigns.bin";
import { ePropType } from "hub-lib/models/VertexProperty.bin";
import moment from "moment";
export function isDate(obj) {
    return obj instanceof Date;
}
var getMetadataMemo = memoizeAsync(function (documentType) { return DataProvider.getMetadata(documentType); });
export function getMinMaxDate(data, dateField, mode) {
    var _a;
    var dateTime = mode === 'min' ? Infinity : -Infinity;
    var dataLength = data.length;
    for (var i = 0; i < dataLength; i++) {
        var valToCompare = (_a = data[i][dateField]) !== null && _a !== void 0 ? _a : 0;
        var currentDate = (isDate(valToCompare) ? valToCompare : new Date(valToCompare)).getTime();
        if ((mode === 'min' && currentDate < dateTime) || (mode === 'max' && currentDate > dateTime)) {
            dateTime = currentDate;
        }
    }
    return new Date(dateTime);
}
export function BuildProperties(documentType, indicateurs, basesProperties) {
    if (basesProperties === void 0) { basesProperties = []; }
    return __awaiter(this, void 0, void 0, function () {
        var allIndicateurs_1, addIndicateurFromField, properties, aliases, subPropertiesToRemove;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (![ref_Messages.name, ref_Campaigns.name].includes(documentType)) return [3 /*break*/, 2];
                    return [4 /*yield*/, AggregatorFactory.GetInstance().Get(documentType).Provide()];
                case 1:
                    allIndicateurs_1 = _a.sent();
                    addIndicateurFromField = function (field) {
                        var _a;
                        var ind = (_a = allIndicateurs_1.find(function (i) { return i.indicateur.field == field; })) === null || _a === void 0 ? void 0 : _a.indicateur;
                        if (ind && !indicateurs.some(function (i) { return i.field == field; }))
                            indicateurs.push(ind);
                    };
                    //addIndicateurFromField("Currency");
                    //addIndicateurFromField("ReturnedCurrency");
                    addIndicateurFromField("Media");
                    addIndicateurFromField("Status");
                    _a.label = 2;
                case 2:
                    properties = distinct(__spreadArray(__spreadArray([], basesProperties, true), EngineTools.BuildPropertiesFromIndicateurs(indicateurs), true)).filter(Boolean);
                    aliases = basesProperties.map(function (p) { var _a; return (_a = p.split(' as ')) === null || _a === void 0 ? void 0 : _a[1]; }).filter(Boolean);
                    subPropertiesToRemove = properties
                        .filter(function (p) { return p.includes('.') && !p.includes(' as ') && !p.includes('.first('); })
                        .map(function (p) { return ({ base: p.split('.')[0], toRemove: p }); }).filter(function (p) { return properties.includes(p.base); });
                    return [2 /*return*/, properties
                            .filter(function (p) { return !aliases.includes(p); })
                            .filter(function (p) { return !subPropertiesToRemove.some(function (sp) { return sp.toRemove == p; }); })];
            }
        });
    });
}
export function getMinDate(data, dateField) {
    return getMinMaxDate(data, dateField, 'min');
}
export function getMaxDate(data, dateField) {
    return getMinMaxDate(data, dateField, 'max');
}
export var FormatCellsMemoized = memoizeAsyncBase(function (documentType, indicateur, cells) {
    var ress = EngineTools.FormatCells(documentType, indicateur, cells);
    return ress;
});
var EngineTools = /** @class */ (function () {
    function EngineTools() {
    }
    EngineTools.groupElementsByDate = function (elements) {
        var _b, _c;
        var groups = [];
        // convert string dates to Date
        elements.forEach(function (e) {
            if (typeof e['Start'] == "string")
                e['Start'] = new Date(e['Start']);
            if (typeof e['End'] == "string")
                e['End'] = new Date(e['End']);
        });
        // order elements by start date
        elements = __spreadArray([], elements, true).sort(function (a, b) {
            var _b, _c, _d, _e;
            var aStart = a['Start'];
            var bStart = b['Start'];
            return ((_c = (_b = aStart === null || aStart === void 0 ? void 0 : aStart.getTime) === null || _b === void 0 ? void 0 : _b.call(aStart)) !== null && _c !== void 0 ? _c : 0) - ((_e = (_d = bStart === null || bStart === void 0 ? void 0 : bStart.getTime) === null || _d === void 0 ? void 0 : _d.call(bStart)) !== null && _e !== void 0 ? _e : 0);
        });
        var currentGroup = null;
        for (var _i = 0, elements_1 = elements; _i < elements_1.length; _i++) {
            var element = elements_1[_i];
            if (!currentGroup) {
                currentGroup = {
                    Start: element['Start'],
                    End: element['End'],
                    Data: [element]
                };
                groups.push(currentGroup);
            }
            else {
                var currentGroupEnd = currentGroup.End;
                var elementStart = element['Start'];
                if (currentGroupEnd && elementStart) {
                    if (((_b = elementStart === null || elementStart === void 0 ? void 0 : elementStart.getTime) === null || _b === void 0 ? void 0 : _b.call(elementStart)) <= ((_c = currentGroupEnd === null || currentGroupEnd === void 0 ? void 0 : currentGroupEnd.getTime) === null || _c === void 0 ? void 0 : _c.call(currentGroupEnd))) {
                        currentGroup.Data.push(element);
                        currentGroup.End = getMaxDate(currentGroup.Data, "End");
                    }
                    else {
                        currentGroup = {
                            Start: element['Start'],
                            End: element['End'],
                            Data: [element]
                        };
                        groups.push(currentGroup);
                    }
                }
                else {
                    //
                }
            }
        }
        return groups;
    };
    EngineTools.groupElementsByWeek = function (elements) {
        var groups = [];
        // convert string dates to Date
        elements.forEach(function (e) {
            if (typeof e['Start'] == "string")
                e['Start'] = new Date(e['Start']);
            if (typeof e['End'] == "string")
                e['End'] = new Date(e['End']);
        });
        // order elements by start date
        elements = __spreadArray([], elements, true).sort(function (a, b) {
            var _b, _c, _d, _e;
            var aStart = a['Start'];
            var bStart = b['Start'];
            return ((_c = (_b = aStart === null || aStart === void 0 ? void 0 : aStart.getTime) === null || _b === void 0 ? void 0 : _b.call(aStart)) !== null && _c !== void 0 ? _c : 0) - ((_e = (_d = bStart === null || bStart === void 0 ? void 0 : bStart.getTime) === null || _d === void 0 ? void 0 : _d.call(bStart)) !== null && _e !== void 0 ? _e : 0);
        });
        var currentGroup = null;
        for (var _i = 0, elements_2 = elements; _i < elements_2.length; _i++) {
            var element = elements_2[_i];
            if (!currentGroup) {
                currentGroup = {
                    Start: element['Start'],
                    End: element['End'],
                    Weeks: getUniqueWeekYears(element['Start'], element['End']),
                    Data: [element]
                };
                groups.push(currentGroup);
            }
            else {
                var currentGroupEnd = currentGroup.End;
                var elementStart = element['Start'];
                if (currentGroupEnd && elementStart && getUniqueWeekYears(elementStart, elementStart).some(function (w) { return currentGroup.Weeks.includes(w); })) {
                    currentGroup.Data.push(element);
                    currentGroup.End = getMaxDate(currentGroup.Data, "End");
                    currentGroup.Weeks = getUniqueWeekYears(currentGroup.Start, currentGroup.End);
                }
                else {
                    currentGroup = {
                        Start: element['Start'],
                        End: element['End'],
                        Weeks: getUniqueWeekYears(element['Start'], element['End']),
                        Data: [element]
                    };
                    groups.push(currentGroup);
                }
            }
        }
        return groups;
    };
    EngineTools.groupElementsByExactDate = function (elements) {
        var groups = [];
        // convert string dates to Date
        elements.forEach(function (e) {
            if (typeof e['Start'] == "string")
                e['Start'] = new Date(e['Start']);
            if (typeof e['End'] == "string")
                e['End'] = new Date(e['End']);
        });
        // order elements by start date
        elements = __spreadArray([], elements, true).sort(function (a, b) {
            var _b, _c, _d, _e;
            var aStart = a['Start'];
            var bStart = b['Start'];
            return ((_c = (_b = aStart === null || aStart === void 0 ? void 0 : aStart.getTime) === null || _b === void 0 ? void 0 : _b.call(aStart)) !== null && _c !== void 0 ? _c : 0) - ((_e = (_d = bStart === null || bStart === void 0 ? void 0 : bStart.getTime) === null || _d === void 0 ? void 0 : _d.call(bStart)) !== null && _e !== void 0 ? _e : 0);
        });
        var _loop_1 = function (element) {
            // on cherche s'il y a un groupe avec la meme date de début et de fin
            var group = groups.find(function (g) {
                var _b, _c, _d, _e;
                var gStart = g.Start;
                var gEnd = g.End;
                var elementStart = element['Start'];
                var elementEnd = element['End'];
                return ((_b = gStart === null || gStart === void 0 ? void 0 : gStart.getTime) === null || _b === void 0 ? void 0 : _b.call(gStart)) === ((_c = elementStart === null || elementStart === void 0 ? void 0 : elementStart.getTime) === null || _c === void 0 ? void 0 : _c.call(elementStart)) && ((_d = gEnd === null || gEnd === void 0 ? void 0 : gEnd.getTime) === null || _d === void 0 ? void 0 : _d.call(gEnd)) === ((_e = elementEnd === null || elementEnd === void 0 ? void 0 : elementEnd.getTime) === null || _e === void 0 ? void 0 : _e.call(elementEnd));
            });
            // si on en a un alors on ajoute l'élément aux data sinon on ajoute un nouveau groupe
            if (group) {
                group.Data.push(element);
            }
            else {
                groups.push({
                    Start: element['Start'],
                    End: element['End'],
                    Data: [element]
                });
            }
        };
        for (var _i = 0, elements_3 = elements; _i < elements_3.length; _i++) {
            var element = elements_3[_i];
            _loop_1(element);
        }
        return groups;
    };
    EngineTools.AddCurrencyToCells = function (cellValue, ind, _data, allCurrencies) {
        var _b, _c, _d;
        var getElement = function (ind, prop) { return function (e) {
            var _b;
            var subPropName = (_b = ind === null || ind === void 0 ? void 0 : ind['options']) === null || _b === void 0 ? void 0 : _b['subProperty'];
            var res = subPropName ? e === null || e === void 0 ? void 0 : e[(ind === null || ind === void 0 ? void 0 : ind.field) + subPropName.replace(/\./g, '') + prop] : e === null || e === void 0 ? void 0 : e[prop];
            return res;
        }; };
        var data = ((_b = ind === null || ind === void 0 ? void 0 : ind.options) === null || _b === void 0 ? void 0 : _b.linksDescriptor)
            ? _data === null || _data === void 0 ? void 0 : _data.flatMap(function (d) { return d[ind.options.linksDescriptor.className + '-' + ind.options.linksDescriptor.property]; })
            : _data;
        switch (ind.valueType) {
            case eKPIType.Price:
            case eKPIType.PriceBound:
                var currencies = distinct(data.map(getElement(ind, 'Currency')).filter(Boolean));
                cellValue.Options = {
                    Currencies: currencies,
                    CurrencyCode: (currencies === null || currencies === void 0 ? void 0 : currencies.length) == 1 ? (_c = allCurrencies === null || allCurrencies === void 0 ? void 0 : allCurrencies[currencies[0]]) === null || _c === void 0 ? void 0 : _c.Code : null
                };
                break;
            case eKPIType.PriceReturned:
                var currenciesRet = distinct(data.map(getElement(ind, 'ReturnedCurrency')).filter(Boolean));
                cellValue.Options = {
                    Currencies: currenciesRet,
                    CurrencyCode: (currenciesRet === null || currenciesRet === void 0 ? void 0 : currenciesRet.length) == 1 ? (_d = allCurrencies === null || allCurrencies === void 0 ? void 0 : allCurrencies[currenciesRet[0]]) === null || _d === void 0 ? void 0 : _d.Code : null
                };
                break;
        }
    };
    EngineTools.FormatCells = function (documentType, indicateur, cells, src) {
        var _b, _c, _d, _e, _f, _g, _h, _j;
        return __awaiter(this, void 0, void 0, function () {
            var _k, indicateurInfo_1, prop, propertyTypes, metadata;
            return __generator(this, function (_l) {
                switch (_l.label) {
                    case 0:
                        if (!(cells === null || cells === void 0 ? void 0 : cells.length))
                            return [2 /*return*/, cells];
                        return [4 /*yield*/, ReleaseEventLoop()];
                    case 1:
                        _l.sent();
                        // particular case for % direction, force the template to be a percent template
                        if (isDirectionPercent((_b = indicateur === null || indicateur === void 0 ? void 0 : indicateur.optionsBase) === null || _b === void 0 ? void 0 : _b.direction))
                            return [2 /*return*/, EngineTools.applyPercentTemplate(cells)];
                        _k = indicateur.type;
                        switch (_k) {
                            case eIndicateurType.info: return [3 /*break*/, 2];
                            case eIndicateurType.join: return [3 /*break*/, 7];
                            case eIndicateurType.kpi: return [3 /*break*/, 9];
                            case eIndicateurType.discount: return [3 /*break*/, 9];
                            case eIndicateurType.computed: return [3 /*break*/, 9];
                            case eIndicateurType.link: return [3 /*break*/, 11];
                        }
                        return [3 /*break*/, 12];
                    case 2:
                        indicateurInfo_1 = indicateur;
                        if (((_d = (_c = indicateurInfo_1 === null || indicateurInfo_1 === void 0 ? void 0 : indicateurInfo_1.options) === null || _c === void 0 ? void 0 : _c.formater) === null || _d === void 0 ? void 0 : _d.type) == "moment")
                            return [2 /*return*/, EngineTools.applyMomentTemplate(cells, indicateurInfo_1)];
                        prop = void 0;
                        if (!((_f = (_e = indicateurInfo_1 === null || indicateurInfo_1 === void 0 ? void 0 : indicateurInfo_1.field) === null || _e === void 0 ? void 0 : _e.startsWith) === null || _f === void 0 ? void 0 : _f.call(_e, "".concat(propertyOf('ModelProperties'), ".")))) return [3 /*break*/, 4];
                        return [4 /*yield*/, DataProvider.search(ref_PropertyType.name)];
                    case 3:
                        propertyTypes = _l.sent();
                        prop = propertyTypes === null || propertyTypes === void 0 ? void 0 : propertyTypes.find(function (p) { return p.Type === indicateurInfo_1.field.replace("".concat(propertyOf('ModelProperties'), "."), ""); });
                        _l.label = 4;
                    case 4: return [4 /*yield*/, getMetadataMemo((_j = (_h = (_g = indicateurInfo_1 === null || indicateurInfo_1 === void 0 ? void 0 : indicateurInfo_1.options) === null || _g === void 0 ? void 0 : _g.linksDescriptor) === null || _h === void 0 ? void 0 : _h.className) !== null && _j !== void 0 ? _j : documentType)];
                    case 5:
                        metadata = _l.sent();
                        return [4 /*yield*/, EngineTools.FormatCellInfo(cells, metadata, indicateurInfo_1, src, prop)];
                    case 6:
                        _l.sent();
                        return [3 /*break*/, 13];
                    case 7: return [4 /*yield*/, EngineTools.FormatJoinCell(documentType, indicateur, cells, src)];
                    case 8:
                        _l.sent();
                        return [3 /*break*/, 13];
                    case 9: return [4 /*yield*/, EngineTools.FormatCellKPIDiscount(cells, indicateur)];
                    case 10:
                        _l.sent();
                        return [3 /*break*/, 13];
                    case 11:
                        cells === null || cells === void 0 ? void 0 : cells.forEach(function (c) {
                            var _b, _c;
                            if (Array.isArray(c.Value))
                                c.Formated = (_b = (c.Value.length > 0 ? 1 : 0)) === null || _b === void 0 ? void 0 : _b.toString();
                            if (typeof c.Value == "number")
                                c.Formated = (_c = (c.Value > 0 ? 1 : 0)) === null || _c === void 0 ? void 0 : _c.toString();
                            else
                                c.Formated = "";
                        });
                        return [3 /*break*/, 13];
                    case 12:
                        console.error("FormatCells: type not found: ".concat(indicateur.type));
                        _l.label = 13;
                    case 13: return [2 /*return*/, cells];
                }
            });
        });
    };
    /**
     * Apply a template for moment values
     * @param cells
     * @param indicateurInfo
     * @returns
     */
    EngineTools.applyMomentTemplate = function (cells, indicateurInfo) {
        var _b, e_1, _c, _d;
        var _e, _f, _g, _h, _j, _k, _l;
        return __awaiter(this, void 0, void 0, function () {
            var _m, _o, _p, cell, e_1_1;
            return __generator(this, function (_q) {
                switch (_q.label) {
                    case 0:
                        if (!(((_f = (_e = indicateurInfo === null || indicateurInfo === void 0 ? void 0 : indicateurInfo.options) === null || _e === void 0 ? void 0 : _e.formater) === null || _f === void 0 ? void 0 : _f.type) == "moment")) return [3 /*break*/, 12];
                        _q.label = 1;
                    case 1:
                        _q.trys.push([1, 6, 7, 12]);
                        _m = true, _o = __asyncValues(splitBlocks(cells));
                        _q.label = 2;
                    case 2: return [4 /*yield*/, _o.next()];
                    case 3:
                        if (!(_p = _q.sent(), _b = _p.done, !_b)) return [3 /*break*/, 5];
                        _d = _p.value;
                        _m = false;
                        try {
                            cell = _d;
                            if (typeof cell.Value != "number" && !isDate(cell.Value)) {
                                if (typeof cell.Value == "string")
                                    cell.Formated = cell.Value;
                                return [3 /*break*/, 4];
                            }
                            if (indicateurInfo.options.formater.format) {
                                switch (indicateurInfo.options.formater.format) {
                                    case "MMMM":
                                        cell.Formated = Trad("month_" + cell.Value);
                                        break;
                                    case "MM":
                                        cell.Formated = (_g = (cell.Value < 10 ? "0" + cell.Value : cell.Value)) === null || _g === void 0 ? void 0 : _g.toString();
                                        break;
                                    case "WW":
                                        cell.Formated = Trad("week_very_short") + ((_h = (cell.Value < 10 ? "0" + cell.Value : cell.Value)) === null || _h === void 0 ? void 0 : _h.toString());
                                        ;
                                        break;
                                    case "YYYY":
                                        cell.Formated = (_j = cell.Value) === null || _j === void 0 ? void 0 : _j.toString();
                                        break;
                                    case "DD":
                                        // write the day with 2 digits
                                        cell.Formated = (_k = (cell.Value < 10 ? "0" + cell.Value : cell.Value)) === null || _k === void 0 ? void 0 : _k.toString();
                                        break;
                                    case "[T]Q":
                                        cell.Formated = "T" + cell.Value;
                                        break;
                                    default:
                                        cell.Formated = (_l = cell.Value) === null || _l === void 0 ? void 0 : _l.toString();
                                        break;
                                }
                            }
                            if (indicateurInfo.options.formater.periodicity) {
                                switch (indicateurInfo.options.formater.periodicity) {
                                    case "datedWeek":
                                        cell.Formated = Trad("week_of") + ' ' + GetCellTemplate(ePropType.Date)(moment(new Date(cell.Value)).startOf("week"));
                                        break;
                                    case "semester":
                                        cell.Formated = "S" + cell.Value;
                                        break;
                                    default:
                                        break;
                                }
                            }
                        }
                        finally {
                            _m = true;
                        }
                        _q.label = 4;
                    case 4: return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 12];
                    case 6:
                        e_1_1 = _q.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 12];
                    case 7:
                        _q.trys.push([7, , 10, 11]);
                        if (!(!_m && !_b && (_c = _o.return))) return [3 /*break*/, 9];
                        return [4 /*yield*/, _c.call(_o)];
                    case 8:
                        _q.sent();
                        _q.label = 9;
                    case 9: return [3 /*break*/, 11];
                    case 10:
                        if (e_1) throw e_1.error;
                        return [7 /*endfinally*/];
                    case 11: return [7 /*endfinally*/];
                    case 12: return [2 /*return*/, cells];
                }
            });
        });
    };
    /**
     * Apply a template for percent values
     * @param cells
     * @returns
     */
    EngineTools.applyPercentTemplate = function (cells) {
        var _b, e_2, _c, _d;
        return __awaiter(this, void 0, void 0, function () {
            var template, _e, _f, _g, cell, e_2_1;
            return __generator(this, function (_h) {
                switch (_h.label) {
                    case 0:
                        template = GetKPITemplate(eKPIType.Percent);
                        _h.label = 1;
                    case 1:
                        _h.trys.push([1, 6, 7, 12]);
                        _e = true, _f = __asyncValues(splitBlocks(cells));
                        _h.label = 2;
                    case 2: return [4 /*yield*/, _f.next()];
                    case 3:
                        if (!(_g = _h.sent(), _b = _g.done, !_b)) return [3 /*break*/, 5];
                        _d = _g.value;
                        _e = false;
                        try {
                            cell = _d;
                            if (isNaN(cell.Value))
                                cell.Formated = "-";
                            else
                                cell.Formated = template(cell.Value);
                        }
                        finally {
                            _e = true;
                        }
                        _h.label = 4;
                    case 4: return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 12];
                    case 6:
                        e_2_1 = _h.sent();
                        e_2 = { error: e_2_1 };
                        return [3 /*break*/, 12];
                    case 7:
                        _h.trys.push([7, , 10, 11]);
                        if (!(!_e && !_b && (_c = _f.return))) return [3 /*break*/, 9];
                        return [4 /*yield*/, _c.call(_f)];
                    case 8:
                        _h.sent();
                        _h.label = 9;
                    case 9: return [3 /*break*/, 11];
                    case 10:
                        if (e_2) throw e_2.error;
                        return [7 /*endfinally*/];
                    case 11: return [7 /*endfinally*/];
                    case 12: return [2 /*return*/, cells];
                }
            });
        });
    };
    EngineTools.FormatLinkedClassCell = function (cells, prop, src, cellTemplate) {
        var _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var rids, values, time0126, _time0126, dicoMapping, properties, className, meta, body, elements, dicoElements, _t1, memoFormat, _t1end;
            var _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (!cellTemplate)
                            cellTemplate = function (data) { return data; };
                        /* Si on est sur un link alors on récupère les valeurs via le manager pour les formater */
                        //const mgr = ManagerFactory.GetInstance().getManager(prop.linkedClass, { collection: src?.URI });
                        //Formatage des cellules pour gérer les valeurs qui ne sont pas des rids
                        cells.forEach(function (c) { return c.Formated = c.Value; });
                        rids = Array.from(new Set(cells
                            .filter(function (c) { var _b; return (c === null || c === void 0 ? void 0 : c.Value) && (c === null || c === void 0 ? void 0 : c.Value) != "" && (typeof c.Value == "string" || ((_b = c.Value) === null || _b === void 0 ? void 0 : _b.length)); })
                            .map(function (c) { return c.Value; })
                            .reduce(function (a, b) { return (Array.isArray(a) ? a : [a]).concat(Array.isArray(b) ? b : [b]); }, [])
                            .filter(function (v) { return v; })
                            .filter(function (v) { return IsIDDB(v); })));
                        values = [];
                        time0126 = 0;
                        _time0126 = 0;
                        if (!(rids === null || rids === void 0 ? void 0 : rids.length)) return [3 /*break*/, 5];
                        dicoMapping = (_d = {},
                            _d[ref_Periodicity.name] = ref_Property.name,
                            _d);
                        properties = (_b = propsNeededForFormat[prop.linkedClass]) !== null && _b !== void 0 ? _b : ["Name"];
                        return [4 /*yield*/, ReleaseEventLoop()];
                    case 1:
                        _e.sent();
                        time0126 = new Date().getTime();
                        className = (_c = dicoMapping[prop.linkedClass]) !== null && _c !== void 0 ? _c : prop.linkedClass;
                        return [4 /*yield*/, DataProvider.getMetadata(className)];
                    case 2:
                        meta = _e.sent();
                        body = {
                            "@rid": rids,
                            properties: properties
                        };
                        // if the class has an "Active" property, we add [true, false] to the search because we want to get all the elements
                        // even if they are not active. (by default the search is done with Active = true if property is not present)
                        if (meta.some(function (m) { return m.name == "Active"; }))
                            body.Active = [true, false];
                        if (src === null || src === void 0 ? void 0 : src.URI)
                            body.managerProps = { collection: src.URI };
                        return [4 /*yield*/, DataProvider.search(className, body).catch(function (e) { console.error(e); return []; })];
                    case 3:
                        elements = _e.sent();
                        return [4 /*yield*/, ReleaseEventLoop()];
                    case 4:
                        _e.sent();
                        _time0126 = new Date().getTime();
                        // console.log(`[PERF] [FORMAT_TABLE] Get ${prop.linkedClass} (${elements.length}) ${_time0126 - time0126}ms`);
                        values = Sort(prop.linkedClass, elements);
                        _e.label = 5;
                    case 5: 
                    // console.log(`[PERF] [FORMAT_TABLE] Dico ${prop.linkedClass} (${values.length}) BEFORE`);
                    return [4 /*yield*/, ReleaseEventLoop()];
                    case 6:
                        // console.log(`[PERF] [FORMAT_TABLE] Dico ${prop.linkedClass} (${values.length}) BEFORE`);
                        _e.sent();
                        dicoElements = toDictionary(values, function (e) { return e['@rid']; });
                        _t1 = new Date().getTime();
                        memoFormat = memoize(function (element) { return Format(element, null, prop.linkedClass); }, { key: function (element) { return element === null || element === void 0 ? void 0 : element['@rid']; } });
                        return [4 /*yield*/, splitBlocksFor(cells, function (cell) {
                                var _b;
                                if (cell.Value === "#-1:-1") {
                                    cell.Formated = "NC";
                                    return;
                                }
                                if (Array.isArray(cell.Value)) {
                                    cell.Formated = JoinElements(cell.Value
                                        .map(function (v) { var _b; return (_b = dicoElements[v]) !== null && _b !== void 0 ? _b : v; })
                                        .map(function (i) { return typeof i == 'string' ? i : memoFormat(i); })
                                        .map(cellTemplate));
                                }
                                else {
                                    var element = dicoElements[cell.Value];
                                    cell.Formated = cellTemplate((_b = (element ? memoFormat(element) : cell.Value)) !== null && _b !== void 0 ? _b : "");
                                }
                            }, 20000)];
                    case 7:
                        _e.sent();
                        _t1end = new Date().getTime();
                        console.log("[PERF] [FORMAT_TABLE] Format ".concat(cells === null || cells === void 0 ? void 0 : cells.length, " cells ").concat(prop.linkedClass, " (").concat(values.length, ") Request: "), _time0126 - time0126, "ms, Apply: ", _t1end - _t1, " ms");
                        return [2 /*return*/];
                }
            });
        });
    };
    EngineTools.GetCellInfoTemplate = function (indicateur, propertyType) {
        var _b, _c, _d, _e, _f, _g;
        var propTemplate = GetPropTemplate(indicateur.field);
        var propTypeTemplate = GetPropertyTypeTemplate(propertyType);
        var kpitemplate = GetKPITemplate(indicateur.valueType);
        var cellTemplate = (_c = (_b = propTemplate !== null && propTemplate !== void 0 ? propTemplate : propTypeTemplate) !== null && _b !== void 0 ? _b : kpitemplate) !== null && _c !== void 0 ? _c : GetKPITemplate(eKPIType.String);
        if (((_e = (_d = indicateur.options) === null || _d === void 0 ? void 0 : _d.formater) === null || _e === void 0 ? void 0 : _e.type) == "html") {
            var cellBase_1 = cellTemplate;
            cellTemplate = function (str) {
                var toHtml = str ? HTMLToString(str) : str;
                return cellBase_1(toHtml);
            };
        }
        if (((_g = (_f = indicateur.options) === null || _f === void 0 ? void 0 : _f.formater) === null || _g === void 0 ? void 0 : _g.type) == "trad") {
            var cellBase_2 = cellTemplate;
            cellTemplate = function (str) {
                var _b, _c;
                var formater = indicateur.options.formater;
                var strValue = str !== null && str !== void 0 ? str : formater.fallback;
                var translated = (strValue != null) ? Trad(((_b = formater.prefixe) !== null && _b !== void 0 ? _b : "") + strValue + ((_c = formater.suffixe) !== null && _c !== void 0 ? _c : "")) : strValue;
                return cellBase_2(translated);
            };
        }
        return cellTemplate;
    };
    EngineTools.FormatCellInfo = function (cells, props, indicateur, src, propertyType) {
        var _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var prop, cellTemplate;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        prop = (_c = (_b = indicateur === null || indicateur === void 0 ? void 0 : indicateur.options) === null || _b === void 0 ? void 0 : _b["MetaData"]) !== null && _c !== void 0 ? _c : props.find(function (p) { return p.name === indicateur.field; });
                        if (!prop)
                            console.warn("FormatCellInfo: prop not found in metadata: ".concat(indicateur.field));
                        cellTemplate = EngineTools.GetCellInfoTemplate(indicateur, propertyType);
                        if (!((prop === null || prop === void 0 ? void 0 : prop.linkedClass) && indicateur.valueType == eKPIType.Rid)) return [3 /*break*/, 2];
                        return [4 /*yield*/, EngineTools.FormatLinkedClassCell(cells, prop, src, cellTemplate)];
                    case 1:
                        _d.sent();
                        return [3 /*break*/, 4];
                    case 2: 
                    /** si ce n'est pas un link on prend le même template que les tableaux */
                    return [4 /*yield*/, splitBlocksFor(cells, function (cell) {
                            cell.Formated = Array.isArray(cell.Value) ? JoinElements(cell.Value.map(cellTemplate)) : cellTemplate(cell.Value);
                        })];
                    case 3:
                        /** si ce n'est pas un link on prend le même template que les tableaux */
                        _d.sent();
                        _d.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    EngineTools.FormatJoinCell = function (documentType, indicateur, cells, src) {
        var _b, _c;
        return __awaiter(this, void 0, void 0, function () {
            var allSubCells, _loop_2, i, _loop_3, iCell;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        allSubCells = [];
                        _loop_2 = function (i) {
                            var subIndicateur, clonedCells;
                            return __generator(this, function (_e) {
                                switch (_e.label) {
                                    case 0:
                                        subIndicateur = indicateur.indicateurs[i];
                                        clonedCells = cells
                                            .flatMap(function (c, key) { return (toArray(c.Value).every(function (v) { return Array.isArray(v); }) ? c.Value : [c.Value]).map(function (v) { return (__assign(__assign({}, c), { Value: v[i], flag: key })); }); });
                                        return [4 /*yield*/, EngineTools.FormatCells(documentType, subIndicateur, clonedCells, src)];
                                    case 1:
                                        _e.sent();
                                        allSubCells.push(clonedCells);
                                        return [2 /*return*/];
                                }
                            });
                        };
                        i = 0;
                        _d.label = 1;
                    case 1:
                        if (!(i < indicateur.indicateurs.length)) return [3 /*break*/, 4];
                        return [5 /*yield**/, _loop_2(i)];
                    case 2:
                        _d.sent();
                        _d.label = 3;
                    case 3:
                        i++;
                        return [3 /*break*/, 1];
                    case 4:
                        _loop_3 = function (iCell) {
                            var cell = cells[iCell];
                            var colCells = indicateur.indicateurs.map(function (_, i) {
                                var indicateurCells = allSubCells[i].filter(function (c) { return c['flag'] === iCell; }).map(function (c) { return c.Formated; });
                                return indicateurCells;
                            });
                            if (colCells === null || colCells === void 0 ? void 0 : colCells.length) {
                                var allValues = [];
                                var _loop_4 = function (i) {
                                    var element = colCells.map(function (c) { return c[i]; }).filter(function (c) { return Boolean(c) && c != ""; }).join((_c = (_b = indicateur.options) === null || _b === void 0 ? void 0 : _b.separator) !== null && _c !== void 0 ? _c : " ");
                                    allValues.push(element);
                                };
                                for (var i = 0; i < colCells[0].length; i++) {
                                    _loop_4(i);
                                }
                                cell.Formated = allValues.join(", ");
                            }
                        };
                        for (iCell = 0; iCell < cells.length; iCell++) {
                            _loop_3(iCell);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    EngineTools.FormatCellKPIDiscount = function (cells, indicateur) {
        var _b, e_3, _c, _d;
        var _e, _f, _g, _h;
        return __awaiter(this, void 0, void 0, function () {
            var templateDiscount, currenciesDico, _j, _k, _l, cell, currency, e_3_1;
            return __generator(this, function (_m) {
                switch (_m.label) {
                    case 0:
                        templateDiscount = EngineTools.GetCellInfoTemplate(indicateur);
                        return [4 /*yield*/, EngineTools.GetCurrencies()];
                    case 1:
                        currenciesDico = _m.sent();
                        _m.label = 2;
                    case 2:
                        _m.trys.push([2, 7, 8, 13]);
                        _j = true, _k = __asyncValues(splitBlocks(cells));
                        _m.label = 3;
                    case 3: return [4 /*yield*/, _k.next()];
                    case 4:
                        if (!(_l = _m.sent(), _b = _l.done, !_b)) return [3 /*break*/, 6];
                        _d = _l.value;
                        _j = false;
                        try {
                            cell = _d;
                            cell.Formated = templateDiscount === null || templateDiscount === void 0 ? void 0 : templateDiscount(cell.Value);
                            if (((_f = (_e = cell.Options) === null || _e === void 0 ? void 0 : _e.Currencies) === null || _f === void 0 ? void 0 : _f.length) == 1) {
                                currency = currenciesDico[cell.Options.Currencies[0]];
                                if (currency) {
                                    if (!((_h = (_g = cell.Formated) === null || _g === void 0 ? void 0 : _g.includes) === null || _h === void 0 ? void 0 : _h.call(_g, currency.Code))) {
                                        cell.Formated = FormatPrice(cell.Value, currency.Name);
                                        // cell.Formated = `${cell.Formated} ${currency.Code}`;
                                    }
                                    cell.Options.CurrencyCode = currency.Code;
                                }
                            }
                        }
                        finally {
                            _j = true;
                        }
                        _m.label = 5;
                    case 5: return [3 /*break*/, 3];
                    case 6: return [3 /*break*/, 13];
                    case 7:
                        e_3_1 = _m.sent();
                        e_3 = { error: e_3_1 };
                        return [3 /*break*/, 13];
                    case 8:
                        _m.trys.push([8, , 11, 12]);
                        if (!(!_j && !_b && (_c = _k.return))) return [3 /*break*/, 10];
                        return [4 /*yield*/, _c.call(_k)];
                    case 9:
                        _m.sent();
                        _m.label = 10;
                    case 10: return [3 /*break*/, 12];
                    case 11:
                        if (e_3) throw e_3.error;
                        return [7 /*endfinally*/];
                    case 12: return [7 /*endfinally*/];
                    case 13: return [2 /*return*/];
                }
            });
        });
    };
    EngineTools.BuildLinkPropertyInfo = function (name, options) {
        var _b, _c;
        var property = (_b = options === null || options === void 0 ? void 0 : options.subProperty) !== null && _b !== void 0 ? _b : "Name";
        var alias = (_c = options === null || options === void 0 ? void 0 : options.MetaData) === null || _c === void 0 ? void 0 : _c.name;
        if (!alias)
            alias = "".concat(name).concat(property).replace(/\./g, '');
        if (options === null || options === void 0 ? void 0 : options.subPropertyFallback) {
            var fallbackProperty = "".concat(name, ".").concat(options.subPropertyFallback);
            var fallbackAlias = "".concat(fallbackProperty).replace(/\./g, '');
            return { property: property, alias: alias, fallbackProperty: fallbackProperty, fallbackAlias: fallbackAlias };
        }
        return { property: property, alias: alias };
    };
    EngineTools.BuildLinkPropertyParams = function (name, options) {
        var _b, _c;
        var propInfos = EngineTools.BuildLinkPropertyInfo(name, options);
        var propertyName = "".concat(name, ".").concat(propInfos.property);
        var aliasProperty = "".concat(propertyName, " as ").concat(propInfos.alias);
        var properties = [aliasProperty];
        (_c = (_b = options === null || options === void 0 ? void 0 : options.subPropertyDependencies) === null || _b === void 0 ? void 0 : _b.forEach) === null || _c === void 0 ? void 0 : _c.call(_b, function (dep) {
            properties.push("".concat(name, ".").concat(dep, " as ").concat(propInfos.alias).concat(dep));
        });
        if (propInfos.fallbackProperty) {
            var fallbackProperty = "".concat(name, ".").concat(propInfos.fallbackProperty, " as ").concat(propInfos.fallbackAlias);
            properties.push(fallbackProperty);
        }
        return properties;
    };
    EngineTools.BuildPropertiesFromIndicateurs = function (_indicateurs) {
        var _b;
        _indicateurs = (_b = _indicateurs === null || _indicateurs === void 0 ? void 0 : _indicateurs.filter) === null || _b === void 0 ? void 0 : _b.call(_indicateurs, Boolean);
        var res = __spreadArray(__spreadArray(__spreadArray(__spreadArray([], (_indicateurs !== null && _indicateurs !== void 0 ? _indicateurs : [])
            .filter(function (i) { var _b; return (_b = i.options) === null || _b === void 0 ? void 0 : _b.subProperty; })
            .flatMap(function (i) { return EngineTools.BuildLinkPropertyParams(i.field, i.options); }), true), (_indicateurs !== null && _indicateurs !== void 0 ? _indicateurs : [])
            .flatMap(function (i) { var _b, _c, _d, _e, _f; return (__spreadArray([(_b = i.options) === null || _b === void 0 ? void 0 : _b.subPropertyValueAsKey], (_f = (_e = (_d = (_c = i.options) === null || _c === void 0 ? void 0 : _c.match) === null || _d === void 0 ? void 0 : _d.map) === null || _e === void 0 ? void 0 : _e.call(_d, function (m) { return m.subProperty; })) !== null && _f !== void 0 ? _f : [], true)); })
            .filter(function (k) { return k; }), true), (_indicateurs !== null && _indicateurs !== void 0 ? _indicateurs : [])
            .filter(function (i) { return i.type == eIndicateurType.info; })
            .filter(function (i) { var _b; return (_b = i.options) === null || _b === void 0 ? void 0 : _b.priorityToField; })
            .map(function (i) { var _b; return (_b = i.options) === null || _b === void 0 ? void 0 : _b.priorityToField; }), true), ((_indicateurs !== null && _indicateurs !== void 0 ? _indicateurs : []).map(function (i) { return i.field; }).filter(Boolean)), true).filter(Boolean);
        var computedIndicateurs = (_indicateurs !== null && _indicateurs !== void 0 ? _indicateurs : []).filter(function (i) { return [eIndicateurType.computed, eIndicateurType.join].includes(i.type); });
        if (computedIndicateurs.length > 0)
            res.push.apply(res, EngineTools.BuildPropertiesFromIndicateurs(computedIndicateurs.flatMap(function (i) { return i.indicateurs; })));
        var linkIndicateurs = _indicateurs === null || _indicateurs === void 0 ? void 0 : _indicateurs.filter(function (i) { return i.type == eIndicateurType.link; });
        if (linkIndicateurs.length > 0)
            res.push.apply(res, linkIndicateurs.flatMap(function (i) { var _b, _c; return (_c = (_b = i.options) === null || _b === void 0 ? void 0 : _b.links) === null || _c === void 0 ? void 0 : _c.map(function (ld) { return ld.property; }); }));
        var indicateurDiscounts = _indicateurs === null || _indicateurs === void 0 ? void 0 : _indicateurs.filter(function (i) { return i.type == eIndicateurType.discount; });
        if (indicateurDiscounts.some(function (i) { var _b; return !((_b = i.options) === null || _b === void 0 ? void 0 : _b["barter"]); })) {
            res.push("Discounts");
            res.push("DiscountMode");
        }
        if (indicateurDiscounts.some(function (i) { var _b; return (_b = i.options) === null || _b === void 0 ? void 0 : _b["barter"]; })) {
            res.push("BarterPercents");
        }
        if (_indicateurs === null || _indicateurs === void 0 ? void 0 : _indicateurs.some(function (i) { return i.type == eIndicateurType.kpi; }))
            res.push("KPIs");
        if (_indicateurs === null || _indicateurs === void 0 ? void 0 : _indicateurs.some(function (i) { return IsPrice(i.valueType); }))
            res.push("Currency");
        if (_indicateurs === null || _indicateurs === void 0 ? void 0 : _indicateurs.some(function (i) { return i.valueType == eKPIType.PriceReturned; })) {
            //Le calcul du taux de change nécessite de récupérer le groupe annonceur et l'annonceur
            res.push("AdvertiserGroup");
            res.push("Advertiser");
            res.push("ReturnedCurrency");
        }
        if (res.includes("Discounts"))
            res = res.filter(function (p) { return !p.startsWith("Discounts."); });
        return res;
    };
    EngineTools.BuildPropertiesFromLabelBuilders = function (labels) {
        var properties = [];
        var indicateurs = [];
        for (var _i = 0, labels_1 = labels; _i < labels_1.length; _i++) {
            var label = labels_1[_i];
            switch (label === null || label === void 0 ? void 0 : label.Type) {
                case "property":
                    properties.push(label.Value);
                    break;
                case "indicateur":
                    indicateurs.push(label.Value);
                    break;
                default:
                    break;
            }
        }
        properties.push.apply(properties, EngineTools.BuildPropertiesFromIndicateurs(indicateurs));
        return distinct(properties);
    };
    var _a;
    _a = EngineTools;
    EngineTools.GetCurrencies = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _b;
        return __generator(_a, function (_c) {
            switch (_c.label) {
                case 0:
                    _b = toDictionary;
                    return [4 /*yield*/, DataProvider.searchMemoized(ref_Currencies.name, { admin: true })];
                case 1: return [2 /*return*/, _b.apply(void 0, [_c.sent(), function (c) { return c['@rid']; }])];
            }
        });
    }); };
    return EngineTools;
}());
export { EngineTools };
